@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');

:root{
    --pink:#ED54A5;
    --green:#00bcd9;
    --lightgrey:#ecf0f4;
}

* {
    box-sizing: border-box;
}

.web_hide{
    display: none !important;
}

.mobile_hide{
    display: block !important;
}

body {
    font-family: 'Roboto', sans-serif;
    /* padding: 100px 0; */
	background: var(--lightgrey);
	width: 100%;
	height: 100%;
	font-size: 18px;
	line-height: 1.5;
	color: #222;
}

.main-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

h1 {
    font-weight: bold;
    margin: 0;
    color:white;
}

p {
    font-size: 14px;
    font-weight:100;
    line-height: 20px;
    letter-spacing: .5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}

a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.content {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, .2), 0 10px 10px rgba(0, 0, 0, .2);
    position: absolute;
    overflow: hidden;
    width: 50%;
    max-width: 100%;
    min-height: 550px;    
}

@media only screen and (max-width: 820px){
    .web_hide{
        display: block !important;
    }
    .mobile_hide{
        display: none !important;
    }
    .content{
        width: 100%;
        height: 100%;
    }
    .overlay-container{
        display: none;
    }
    .sign-in-container{
        width: 100% !important;
    }
    .sign-up-container{
        width: 100% !important;
        transform: translateX(0%) !important;
        
    }
    .back-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .editBtn{
        align-self: center;
        font-size: small !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    .nextBtn{
        align-self: center;
        font-size: small !important;
    }
    .info_text{
        font-size: 11px;
    }
    .video-title {
    font-size: 10px !important;
    }
    
    .user-info {
    font-size: 8px !important;
    }
}

.form-container {
    background-image: url('../images/signin-img.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.form-container{
    display: flex;
    flex-direction: column;
    padding:  0 50px;
    height: 80%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #ddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

.form-container input {
    
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.overlay-form-container input {
    
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 80%;
}

button {
    border-radius: 20px;
    border: 1px solid;
    background: #0b83db;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

button:active {
    transform: scale(.95);
}

button:focus {
    outline: none;
}

button.ghost {
    /* background: transparent; */
    border-color: #fff !important;
    background-color: var(--green);
}

.form-container{
    position: absolute;
    top: 0;
    height: 100%;
    transition: all .6s ease-in-out;
    box-shadow: 0px 0px 200px 0px inset;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.sign-up-container {
    left: 0;
    width: 50%;
    z-index: 1;
    opacity: 0;
}

.sign-in-form-container {
    left: 0;
    width: 50%;
    z-index: 1;
    opacity: 0;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform .6s ease-in-out;
    z-index: 100;
}

.overlay {
    background: var(--green);
    background: linear-gradient(to right, #0b83db, #05c7c7) no-repeat 0 0 / cover;
    color: white;
    left: -100%;
    height: 100%;
    width: 100%;
    transform: translateY(0);
    transition: transform .6s ease-in-out;
}

.overlay-panel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    width: 50%;
    text-align: center;
    transform: translateY(0);
    transition: transform .6s ease-in-out;
}

.overlay-right {
    right: 0;
    transform: translateY(0);
}

.overlay-left {
    transform: translateY(0);
}

/* Move signin to right */
.content.right-panel-active .sign-in-container {
    transform: translateY(100%);
}

/* Move overlay to left */
.content.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

/* Bring signup over signin */
.content.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

/* Bring signup over signin */
.content.right-panel-active .sign-in-form-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

/* Move overlay back to right */
.content.right-panel-active .overlay {
    transform: translateX(0);
}

/* Bring back the text to center */
.content.right-panel-active .overlay-left {
    transform: translateY(0);
}

/* Same effect for right */
.content.right-panel-active .overlay-right {
    transform: translateY(0);
}

.footer {
	margin-top: 25px;
	text-align: center;
}


.icons {
	display: flex;
	width: 30px;
	height: 30px;
	letter-spacing: 15px;
	align-items: center;
}

/*   Sign In buttons    */

.btn-google{
    background-color: var(--green) !important; 
    width: 100%;
    height: 50px;
    border-radius: 25px;
    color: white;
    font-weight: 500;
    font-size: 15px !important;
    margin-top: 225px !important;
}
.btn-email{
    background-color: var(--pink) !important; 
    width: 100%;
    height: 50px;
    border-radius: 25px;
    color: white;
    font-size: 15px !important;
    font-weight: 500;
}

.font-bold{
    font-weight: bold;
}


/*   Company form    */

.form-wrap{
	background: rgba(255,255,255,1);
	width: 100%;
	max-width: 850px;
	padding: 50px;
	margin: 0 auto;
	position: relative;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}
.form-wrap:before{
	content: "";
	width: 90%;
	height: calc(100% + 60px);
	left: 0;
	right: 0;
	margin: 0 auto;
	position: absolute;
	top: -30px;
	background: var(--green);
	z-index: -1;
	opacity: 0.8;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}
.form-group{
	margin-bottom: 25px;
}
.form-group > label{
	display: block;
	font-size: 18px;	
	color: #000;
}
.form-control{
	height: 50px;
	background: var(--lightgrey);
	border-color: transparent;
	padding: 0 15px;
	font-size: 16px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.form-control:focus{
	border-color: var(--green);
	-webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}
.btn{
	padding: .657rem .75rem;
	font-size: 18px;
	letter-spacing: 0.050em;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.nextBtn {
    color: #fff;
    background-color: var(--pink);
    border-color: var(--pink);
    border-radius: 30px;
}

.nextBtn:hover {
    color: var(--pink);
    background-color: #ffffff;
    border-color: var(--pink);
        -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.nextBtn:focus, .nextBtn.focus {
    color: var(--pink);
    background-color: #ffffff;
    border-color: var(--pink);
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.nextBtn:not(:disabled):not(.disabled):active, .nextBtn:not(:disabled):not(.disabled).active,
.show > .nextBtn.dropdown-toggle {
    color: var(--pink);
    background-color: #ffffff;
    border-color: var(--pink);
}

.nextBtn:not(:disabled):not(.disabled):active:focus, .nextBtn:not(:disabled):not(.disabled).active:focus,
.show > .nextBtn.dropdown-toggle:focus {
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

/*  password toogle icon   */

.form-group .input-group .input-group-text{
    background: var(--lightgrey);
}

/*  upload company logo  */
.circle-upload {
    width: 100px;
    height: 100px;
    background-color: var(--pink);
    border-radius: 50%; /* Makes it circular */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  .upload-logo {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .circle-upload label {
    cursor: pointer;
    z-index: 1;
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
  
  /* Style the label to resemble a button */
  .circle-upload label {
    padding: 10px 20px;
    
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  .circle-upload .camera{
   margin: 10px !important; 
   font-size:35px;
}

/*         upload cover images                */


/* Style the input */
.upload-image {
    opacity: 0;
    position: absolute;
    z-index: 1;
}

/* Style the preview container */
.upload-preview {
    display: flex;
    flex-wrap: wrap;
    color: white;
    
}

/* Style the individual image boxes */
.upload-box {
    width: 100px;
    height: 100px;
    background-color: var(--pink);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    position: relative;
    cursor: pointer; 
}

/* Style the "+" label within the image boxes */
.upload-box-label {
    font-size: 45px;
    margin-bottom: -15px;
    cursor: pointer;
}

/* Style the text inside the image boxes */
.upload-box-text {
    font-size: 16px;
    padding:0px 5px 10px 5px;
}

/*    change radio button color   */

.jobRadio{
    accent-color: var(--pink) !important;
    height: 20px;
    width: 20px;
}


/*              Profile.js                */

.profile_banner_img_container {
    position: relative;
    width: 100%;
    padding-top: 50%; /* This sets the aspect ratio (height) of the image to 2:1, adjust as needed */
    overflow: hidden;
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, .1), 0 10px 10px rgba(0, 0, 0, 0);

}

.profile_banner_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    object-fit: cover; /* This ensures the image covers the entire container */

}

.profile_banner_row{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.profile_img{
    /* width: 100%;
    border-radius: 15px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, .2), 0 10px 10px rgba(0, 0, 0, 0); */

    border-radius: 15px;
    width: 100%;
    min-height:80px;
    height: 90px;
    object-fit:cover;
    object-position:50% 50%;

}
.location_icon{
    color: var(--pink);
    font-size: 15px;
    font-weight: bold;
}
.key_container{
    border-radius: 15px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, .1), 0 10px 10px rgba(0, 0, 0, 0);
}
.info_container{
    background: var(--lightgrey);
    border-radius: 15px;
}
.profile_link{
    font-weight: bold;
    font-size: large;
    text-decoration: none !important;
}
.profile_link:hover{
    color: var(--pink);
}
.web_link{
    text-decoration: none !important;
}
.web_link:hover{
    color: var(--pink);
}
.post_container{
    border-radius: 15px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, .1), 0 10px 10px rgba(0, 0, 0, 0);
}
.job_text{
    font-weight: bold;
    font-size: medium;
}
.job_link{
    text-decoration: none !important;
}
.job_link:hover{
    color: initial;
}
@media only screen and (max-width: 820px){
    .job_text{
        font-size: xx-small;
    }
}


/*           ViewJob.js           */

.location_icon2{
    color: var(--green);
    font-size: 15px;
    font-weight: bold;
}

.map_image{
    width: 100%;
}

 .css-13cymwt-control{
    min-height: 50px !important;
	background: var(--lightgrey) !important;
	border-color: transparent !important;
	padding: 0 15px !important;
	font-size: 16px !important;
	-webkit-transition: all 0.3s ease-in-out !important;
	-moz-transition: all 0.3s ease-in-out !important;
	-o-transition: all 0.3s ease-in-out !important;
	transition: all 0.3s ease-in-out !important;
}

.css-13cymwt-control:focus{
    border-color: var(--green) !important;
	-webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.css-t3ipsp-control{
    border-color: var(--green) !important;
    box-shadow: initial !important;
    
}
.css-t3ipsp-control:hover{
    border-color: var(--green) !important;
}

.editBtn {
    color: var(--pink);
    background-color: #ffffff;
    border-color: var(--pink);
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.editBtn:hover {
    color: #ffffff;
    background-color: var(--pink);
    border-color: #ffffff;
        -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.editBtn:focus, .editBtn.focus {
    color: #ffffff;
    background-color: var(--pink);
    border-color: #ffffff;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.editBtn:not(:disabled):not(.disabled):active, .editBtn:not(:disabled):not(.disabled).active,
.show > .editBtn.dropdown-toggle {
    color: #ffffff;
    background-color: var(--pink);
    border-color: #ffffff;
}

.editBtn:not(:disabled):not(.disabled):active:focus, .editBtn:not(:disabled):not(.disabled).active:focus,
.show > .editBtn.dropdown-toggle:focus {
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.saveBtn {
    color: var(--pink);
}
.saveBtn:hover {
    color: var(--pink);
}
/* Initially hide the editProfile-container */
.editProfile-container {
    
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    z-index: 1;
    width: 200px; /* Adjust the width as needed */
    right: 0; /* Position it to the right of the button */
    top: -220px; /* Position it just above the button */
  }
  
  /* Style the links inside the editProfile-container */
  .editProfile-container a {
    color:var(--pink);
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s;
  }

  .job_images{
    border-radius: 10px;
    width: 100%;
    /* height: auto; */
    min-height:100px;
    height: 200px;
    object-fit:cover;
    object-position:50% 50%;
  }

  
  /*                       ViewApplicants.css                        */
  
.video-grid-slider {
    width: 100%;
    overflow: hidden;
  }
  
  .video-slide {
    position: relative;
  }
  
  video {
    width: 100%;
    height: auto;
    border-radius: 10px;
    
  }
  
  .video-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.video-title {
  margin: 0;
  font-size: 15px;
  color: white;
}

.user-info {
  margin: 0;
  font-size: 13px;
  color: white;
}
